import React from "react";

function ArtSupplies() {
    return (
        <div>
          <h1>Art Supplies</h1>
          <p>미술 도구 홈페이지 .</p>
        </div>
      );

}

export default ArtSupplies;