import React from 'react';

function RankingPage() {
  return (
    <div>
      <h1>Ranking</h1>
      <p>Find the latest updates Rank</p>
    </div>
  );
}

export default RankingPage;
