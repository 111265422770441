import React from 'react';

function EducationPage() {
  return (
    <div>
      <h1>Education Page</h1>
      <p>Here, you will find educational content related to art.</p>
    </div>
  );
}

export default EducationPage;