// src/components/Footer.js
function Footer() {
    return (
      <footer>
        <p>© 2024 Fine Art Portal</p>
      </footer>
    );
  }
  
  export default Footer;
  